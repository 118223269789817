<template>
  <v-card max-height="600px" style="overflow-y:auto;">
    <v-card-title class="white--text primary">
      <span>Field Notes - {{ screen.deliveryType.name }}</span>
      <v-icon 
        @click="showAddFieldNotes = !showAddFieldNotes; setActiveEditNote(null)" 
        :color="(showAddFieldNotes) ? 'red' : 'white'" 
        class="mouse-pointer mt-n1" 
        style="font-size: 26px"
      >
        {{(showAddFieldNotes) ? 'mdi-minus' : 'mdi-plus'}}
      </v-icon>
    </v-card-title>
    <v-row class="ma-5">
      <v-col cols="12">
        <div>
          <v-row>
            <div v-if="showAddFieldNotes" class="ma-2" style="position: relative; width: 96%;">
              <v-textarea
                v-model="note"
                @keyup.ctrl.enter="addFieldNote(); showAddFieldNotes = false;" 
                solo
                label="Add a field note"
                height="70px"
                no-resize
                background-color="#252525"
              />
              
              <div class="d-flex justify-end py-1" style="background: #252525; position: absolute; bottom: 3.2px; left: 0; right: 0;">
                <v-icon 
                  @click.stop="addFieldNote(); showAddFieldNotes = false;" 
                  :color="(note) ? 'white' : 'grey darken-2'" 
                  class="mouse-pointer mr-1"
                >mdi-send</v-icon>
              </div>
            </div>

            <v-col v-if="fieldnotes.length > 0" cols="12">
              <v-row 
                v-for="(item, index) in fieldnotes" 
                :key="index" 
                @click.stop="setActiveEditNote(item.id); showAddFieldNotes = false" 
                class="mb-3 mouse-pointer"
              >
                <div 
                  v-if="activeEditNote !== item.id" 
                  class="pa-1" 
                  style="width: 100%; border: 1px grey solid; border-radius: 6px"
                >
                  <div class="mx-1 d-flex justify-space-between">
                    <div>
                      <h6 class="grey--text">{{item.dateUpdated | moment}}</h6>
                    </div>
                    <div>
                      <v-icon
                        @click.stop="deleteNote(item.id)"
                        small
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </div>
                  </div>
                  <p class="mx-1">{{item.fieldNote}}</p>
                </div>
               
                <div v-else class="mx-auto mt-5" style="position: relative; width: 96%;">
                  <v-textarea
                    v-model="item.fieldNote"
                    @keyup.ctrl.enter="editNote(item); setActiveEditNote(null)" 
                    solo
                    height="400px"
                    no-resize
                    background-color="#252525"
                  />
                  <div class="d-flex justify-space-between py-1" style="background: #252525; position: absolute; bottom: 3.2px; left: 0; right: 0;">
                    <v-icon
                      @click.stop="setActiveEditNote(null)" 
                      color="#6c2e21"
                      class="mouse-pointer ml-1"
                    >mdi-close-circle</v-icon>
                    <v-icon 
                      @click.stop="editNote(item); setActiveEditNote(null)" 
                      color="white"
                      class="mouse-pointer mr-1"
                    >mdi-send</v-icon>
                  </div>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>    
</template>
<script>
  import moment from 'moment'
  import ScreenController from '@/services/controllers/Screen'

  export default {
    filters: {
      moment: function (date) {
        return moment(date).format('DD MMM YYYY')
      },
    },
    props: {
      screen: {
        type: Object,
        required: true,
      },
      fieldnotes: {
        type: Array,
        required: true,
      }
    },
    data(){
      return {
        activeEditNote: null,
        showAddFieldNotes: false,
        note: null,
        updateFieldNoteDialog: false,
        selectedFieldNoteId: null,
        editedFieldNote: null,
      }
    },
    inject: ['updateFieldNote', 'deleteFieldNote'],
    methods: {
      setActiveEditNote(id) {
        this.activeEditNote = id
      },
      async editNote(item) {
        await ScreenController.editFieldNote({
          id: item.id,
          screenId: this.screen.screen.id,
          fieldNote: item.fieldNote,
        })
          .then((res) => {
            // Update field note in fieldnotes
            this.updateFieldNote(res.data)
            this.$root.$emit(
              'snackbarSuccess',
              'Field note edited successfully.',
            )
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },
      async deleteNote (fieldNoteId) {
         await ScreenController.deleteFieldNote({
          screenId: this.screen.screen.id,
          fieldNoteId: parseInt(fieldNoteId),
        })
          .then(() => {
            // Delete field note from fieldnotes
            this.deleteFieldNote(fieldNoteId)
            this.$root.$emit('snackbarSuccess','Field note deleted successfully.')
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', err)
          })
      },

      async addFieldNote() {
        await ScreenController.addFieldNote({
          screenId: this.screen.screen.id,
          fieldNote: this.note,
        })
          .then((res) => {
            // Add res data to fieldnotes
            this.fieldnotes.push(res.data)
            this.$nextTick()
            this.note = null
            this.$root.$emit('snackbarSuccess', 'Field note added successfully.')
          })
      },
    },
  }
</script>
