<template>
    <v-dialog v-model="show" :persistent='!disablePersistent' width="700">
        <v-card class="pb-2">
            <v-card-title class="primary white--text"><span class="text-capitalize">Screen</span> Scope</v-card-title>

            <v-row class="ma-4">
                <!-- Type -->
                <v-col 
                    :cols="(screenEvent.repeatInterval.toLowerCase() === 'weekly') ? 6 : 12"
                    class="ma-0 pa-1"
                >
                    <v-select
                        v-model="screenEvent.repeatInterval"
                        @change="screenEvent.dayOfWeek = '';"
                        label="Event type"
                        :items="['Daily', 'Weekly']"
                        outlined
                    />
                </v-col>

                <!-- Day -->
                <v-col
                    v-if="screenEvent.repeatInterval.toLowerCase() === 'weekly'"
                    cols="6"
                    class="ma-0 pa-1"
                >
                    <v-select
                        v-model="screenEvent.dayOfWeek"
                        label="Day Of Week"
                        :items="[
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Friday',
                            'Saturday',
                            'Sunday',
                        ]"
                        persistent
                        outlined
                        color="primary"
                    />
                </v-col>

                <!-- Start Time -->
                <v-col cols="6" class="ma-0 pa-1">
                    <v-menu
                        ref="startTime"
                        v-model="timePicker.start"
                        :return-value.sync="screenEvent.startTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                v-on="on"
                                v-model="screenEvent.startTime"
                                readonly
                                label="Start Time"
                                append-icon="mdi-clock-start"
                                outlined
                            />
                        </template>
                        <v-time-picker
                            v-if="timePicker.start"
                            v-model="screenEvent.startTime"
                            @click:minute="$refs.startTime.save(screenEvent.startTime)"
                            full-width
                            range
                        />
                    </v-menu>
                </v-col>

                <!-- End Time -->
                <v-col cols="6" class="ma-0 pa-1">
                    <v-menu
                        ref="endTime"
                        v-model="timePicker.end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="screenEvent.endTime"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                label="End Time"
                                append-icon="mdi-clock-end"
                                outlined
                            />
                        </template>
                        <v-time-picker
                            v-if="timePicker.end"
                            v-model="screenEvent.endTime"
                            @click:minute="$refs.endTime.save(screenEvent.endTime)"
                            full-width
                            range
                        />
                    </v-menu>
                </v-col>
            </v-row>

            <v-card-actions class="d-flex justify-end mx-3">
                <v-btn
                    @click="addScope()"
                    :disabled="disableAdd"
                    width="100"
                    color="primary"
                >Add</v-btn>
                <v-btn
                    @click="$emit('closeDialog')"
                    width="100"
                    color="red"
                >Cancel</v-btn>
            </v-card-actions>

            <!-- Show Results -->
            <v-data-table
                v-if="screenEvents && screenEvents.length > 0"
                :headers="timeScheduleHeaders"
                :items="screenEvents"
                :hide-default-footer="false"
                :items-per-page="5"
                item-class="ma-0 pa-0"
            >
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        class="m-2"
                        icon
                        small
                        color="primary"
                        @click="deleteExistingScheduledEventFromScreen(item)"
                    >
                    <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import ScreenController from '@/services/controllers/Screen'

export default {
    props: {
        screen: {
            type: Object,
            required: true,
        },
        showDialog: {
            type: Boolean,
            required: true,
        },
        deliveryType: {
            type: Object,
            required: true,
        },
        screenGroup: {
            type: Object,
            required: true,
        },
    },
    inject: ['setScreenEvents'],
    data: () => ({
        screenEvent: {
            repeatInterval: 'Daily',
            dayOfWeek: '',
            startTime: '',
            endTime: '',
        },

        timePicker: {
            start: false,
            end: false,
        }
    }),
    computed: {
        // Compute showDialog with a setter and getter
        show: {
            get() {
                return this.$props.showDialog
            },
            set() {
                this.$emit('closeDialog')
            }
        },
        disablePersistent() {
            const { startTime, endTime, repeatInterval, dayOfWeek } = this.screenEvent
            if (repeatInterval === 'Daily') {
                if(startTime || endTime) {
                    return false
                }
            }
            if (startTime || endTime || dayOfWeek) {
                return false
            }
            return true
        },


        disableAdd() {
            const { startTime, endTime, repeatInterval, dayOfWeek } = this.screenEvent
            if (repeatInterval === 'Daily') {
                return !startTime || !endTime
            }
            return !startTime || !endTime || !dayOfWeek
        },
        timeScheduleHeaders: {
            get() {
                let headers = []

                headers.push({ text: 'Event Type', align: 'start', sortable: false, value: 'repeatInterval' })
                headers.push({ text: 'Day of Week', align: 'start', sortable: false, value: 'dayOfWeek' })
                headers.push({ text: 'Start Time', align: 'start', sortable: false, value: 'startTime' })
                headers.push({ text: 'End Time', align: 'start', sortable: false, value: 'endTime' })
                headers.push({ text: 'Actions', align: 'start', sortable: false, value: 'actions' })
                
                return headers
            }
        },
        
        screenEvents: {
            get() {
                let { screenEvents } = this.screenGroup
                return screenEvents
            }
        },

        localScreen: {
            get() {
                // Dynamic Screen
                const dynamicScreen = {
                    ...this.screen.dynamicScreen,
                    machineType: (this.screen.dynamicScreen.machineType) ? this.screen.dynamicScreen.machineType.name : undefined,
                    operatingSystem: (this.screen.dynamicScreen.operatingSystem) ? this.screen.dynamicScreen.operatingSystem.name : undefined,
                    playerSoftware: (this.screen.dynamicScreen.playerSoftware) ? this.screen.dynamicScreen.playerSoftware.name : undefined,
                    publishType: (this.screen.dynamicScreen.publishType) ? this.screen.dynamicScreen.publishType.name : undefined,
                }

                // Screen
                let screen = {
                    ...this.screen,
                    dynamicScreen,
                    mediaOwner: this.screen.mediaOwner.friendlyName,
                    brandingBadgeScreenId: this.screen.brandingBadgeScreen ? this.screen.brandingBadgeScreen.id : 0,
                    parentScreenId: this.screen.parentScreen ? this.screen.parentScreen.id : 0,
                    screenGroupId: this.screenGroup.id,
                    deliveryTypeId: this.deliveryType.id,
                }
                screen.screenEvents = [...this.screenGroup.screenEvents]
                // Remove redundant fields
                delete screen.brandingBadgeScreen
                delete screen.parentScreen

                return screen
            }
        },
    },

    methods: {
        async addScope() {
            // Clone screenEvents so we can append to the array
            let screenEvents = this.localScreen.screenEvents ? [...this.localScreen.screenEvents] : []
            // Set dayOfWeek to Monday if repeatInterval is Weekly
            if(!this.screenEvent.dayOfWeek || this.screenEvent.dayOfWeek.length === 0)
               this.screenEvent.dayOfWeek = "Monday" 
            // Push screenEvent to ScreenEvents array
            screenEvents.push(this.screenEvent)
            // Create editedScreen for submission to end point
            const editedScreen = {
                ...this.localScreen,
                screenEvents,
            }
            // Submit editedScreen to end point
            await ScreenController.editScreen(editedScreen)
            .then(() => {
                this.setScreenEvents(screenEvents)
                this.$root.$emit('snackbarSuccess', 'Screen event added successfully.') 
            })
            .catch((err) => { this.$root.$emit('snackbarError', ''+ err.response.data.message) })
        },

        async deleteExistingScheduledEventFromScreen (item) {
            // Clone screenEvents so we can append to the array
            let screenEvents = JSON.parse(JSON.stringify([ ...this.localScreen.screenEvents ]))
            // Find index of item in screenEvents
            const index = screenEvents.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item))
            // Remove item from screenEvents
            screenEvents.splice(index, 1)
            // Create editedScreen for submission to end point
            const editedScreen = {
                ...this.localScreen,
                screenEvents,
            }

            // Submit editedScreen to end point
            await ScreenController.editScreen(editedScreen)
            .then( () => { 
                this.setScreenEvents(screenEvents)
                this.$root.$emit('snackbarSuccess', 'Screen event removed successfully.') 
            })
            .catch((err) => { this.$root.$emit('snackbarError', ''+ err.response.data.message) })
        },
    }
}
</script>

<style scoped>

</style>