var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":!_vm.disablePersistent,"width":"700"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Screen")]),_vm._v(" Scope")]),_c('v-row',{staticClass:"ma-4"},[_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":(_vm.screenEvent.repeatInterval.toLowerCase() === 'weekly') ? 6 : 12}},[_c('v-select',{attrs:{"label":"Event type","items":['Daily', 'Weekly'],"outlined":""},on:{"change":function($event){_vm.screenEvent.dayOfWeek = '';}},model:{value:(_vm.screenEvent.repeatInterval),callback:function ($$v) {_vm.$set(_vm.screenEvent, "repeatInterval", $$v)},expression:"screenEvent.repeatInterval"}})],1),(_vm.screenEvent.repeatInterval.toLowerCase() === 'weekly')?_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Day Of Week","items":[
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                        'Sunday' ],"persistent":"","outlined":"","color":"primary"},model:{value:(_vm.screenEvent.dayOfWeek),callback:function ($$v) {_vm.$set(_vm.screenEvent, "dayOfWeek", $$v)},expression:"screenEvent.dayOfWeek"}})],1):_vm._e(),_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":"6"}},[_c('v-menu',{ref:"startTime",attrs:{"return-value":_vm.screenEvent.startTime,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.screenEvent, "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.screenEvent, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Start Time","append-icon":"mdi-clock-start","outlined":""},model:{value:(_vm.screenEvent.startTime),callback:function ($$v) {_vm.$set(_vm.screenEvent, "startTime", $$v)},expression:"screenEvent.startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timePicker.start),callback:function ($$v) {_vm.$set(_vm.timePicker, "start", $$v)},expression:"timePicker.start"}},[(_vm.timePicker.start)?_c('v-time-picker',{attrs:{"full-width":"","range":""},on:{"click:minute":function($event){return _vm.$refs.startTime.save(_vm.screenEvent.startTime)}},model:{value:(_vm.screenEvent.startTime),callback:function ($$v) {_vm.$set(_vm.screenEvent, "startTime", $$v)},expression:"screenEvent.startTime"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":"6"}},[_c('v-menu',{ref:"endTime",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"End Time","append-icon":"mdi-clock-end","outlined":""},model:{value:(_vm.screenEvent.endTime),callback:function ($$v) {_vm.$set(_vm.screenEvent, "endTime", $$v)},expression:"screenEvent.endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timePicker.end),callback:function ($$v) {_vm.$set(_vm.timePicker, "end", $$v)},expression:"timePicker.end"}},[(_vm.timePicker.end)?_c('v-time-picker',{attrs:{"full-width":"","range":""},on:{"click:minute":function($event){return _vm.$refs.endTime.save(_vm.screenEvent.endTime)}},model:{value:(_vm.screenEvent.endTime),callback:function ($$v) {_vm.$set(_vm.screenEvent, "endTime", $$v)},expression:"screenEvent.endTime"}}):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end mx-3"},[_c('v-btn',{attrs:{"disabled":_vm.disableAdd,"width":"100","color":"primary"},on:{"click":function($event){return _vm.addScope()}}},[_vm._v("Add")]),_c('v-btn',{attrs:{"width":"100","color":"red"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v("Cancel")])],1),(_vm.screenEvents && _vm.screenEvents.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.timeScheduleHeaders,"items":_vm.screenEvents,"hide-default-footer":false,"items-per-page":5,"item-class":"ma-0 pa-0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"m-2",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.deleteExistingScheduledEventFromScreen(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,2016298686)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }